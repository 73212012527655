import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const tableHeader = ['VM details', 'OS', 'Volume', ' '];

function CollapsibleTable() {
  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: 'rgba(31, 32, 35, 0.28)'
        }}>
        {tableHeader.map((header, index) => (
          <TableCell
            align="left"
            key={index}
            sx={{
              width: `${index === 1 ? '150px' : 'auto'}`
            }}>
            <Typography
              sx={{
                border: 'none',
                color: '#FFFFFF80',
                height: '22px',
                alignItems: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap'
              }}>
              {header}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default CollapsibleTable;
