import { Box, Paper, Table, TableContainer, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Buttons from 'components/buttons';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setBulkOnboardSchedulePayload,
  setModalPreference,
  setScheduleModalProps
} from '../../../store/unmanagedVm.slice';
import { InfoBoxStyle } from '../../infoBox';
import Star from '../../../assets/stars.svg';
import TableHeader from './TableHeader';
import { ScheduleInstanceInfo } from '../../../types';
import ConfirmOnboardTableRows from './TableRow';
import {
  calculateTotalSavingFromScheduleModalPropsList,
  calculateTotalSavingFromScheduleModalPropsListWithConversion
} from '../../../utils/helper';

interface ConfirmScheduleOnboardModalProps {
  handleOnboard?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleModalClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ConfirmScheduleOnboard: FC<ConfirmScheduleOnboardModalProps> = (props) => {
  const { scheduleModalPropsList, bulkOnboardSchedulePayload } = useAppSelector(
    (state) => state.unmanagedVm
  );
  const [instanceInfo, setInstanceInfo] = useState<ScheduleInstanceInfo[]>(
    scheduleModalPropsList?.scheduleInstanceInfo || []
  );
  const [disableBtn, setDisableBtn] = useState<boolean>(instanceInfo.length > 0 ? false : true);
  const getTotalSavings = () => {
    return scheduleModalPropsList?.totalSaving;
  };
  const [savings, SetSavings] = useState<number | undefined>(() => getTotalSavings());
  const dispatch = useAppDispatch();
  const { handleModalClose } = props;

  const calculateSavingsOneveryChange = () => {
    let totalSavings = 0;
    let totalSavingWithConversions = 0;
    instanceInfo.forEach((instance) => {
      totalSavings += calculateTotalSavingFromScheduleModalPropsList(instance);
      totalSavingWithConversions +=
      calculateTotalSavingFromScheduleModalPropsListWithConversion(instance);
    });

    dispatch(
      setScheduleModalProps({
        totalSaving: totalSavings,
        totalSavingWithConversion: totalSavingWithConversions
      })
    );
  };

  useEffect(() => {
    SetSavings(getTotalSavings());
  }, [scheduleModalPropsList]);

  useEffect(() => {
    if (instanceInfo.length === 0) setDisableBtn(true);
    else setDisableBtn(false);
    calculateSavingsOneveryChange();
  }, [instanceInfo]);

  const handleNextModal = () => {
    dispatch(
      setScheduleModalProps({
        scheduleInstanceInfo: instanceInfo
      })
    );

    const instanceMountPointSetWithUpdatedScheduleInstanceInfo = new Set(
      instanceInfo?.flatMap(
        (item) =>
          item.partitionDetailsInfo?.map(
            (partition) => `${item.instanceId}_${partition.mountPoint}`
          ) || []
      )
    );

    const updatedMountPointInfo = bulkOnboardSchedulePayload?.mountPointInfo.filter(
      (info: { instanceId: string; mountPoint: string }) =>
        instanceMountPointSetWithUpdatedScheduleInstanceInfo.has(
          `${info.instanceId}_${info.mountPoint}`
        )
    );
    dispatch(setBulkOnboardSchedulePayload({ mountPointInfo: updatedMountPointInfo }));
    dispatch(setModalPreference('onboard'));
  };

  return (
    <Box>
      <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px" mb={1}>
        Onboarding Volumes
      </Typography>
      {scheduleModalPropsList && (
        <Box
          sx={{
            ...InfoBoxStyle
          }}>
          <img src={Star} alt="Star" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}>
            <Typography
              variant="body2"
              gutterBottom
              fontSize={16}
              lineHeight="24px"
              color="primary"
              mb={0}>
              Savings with this onboarding
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              fontSize={16}
              lineHeight="24px"
              color="primary"
              mb={0}>
              {'$' + savings}
            </Typography>
          </Box>
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Box sx={{ height: '3px', backgroundColor: '#ddff55', flex: 1 }} />
        <Box sx={{ height: '3px', backgroundColor: 'rgba(221, 255, 85, 0.08)', flex: 1 }} />
      </Box>

      <Box
        sx={{
          height: '52px',
          display: 'flex',
          alignItems: 'center',
          fontWeight: '700',
          borderBottom: '1px solid #454546'
        }}>
        {bulkOnboardSchedulePayload?.mountPointInfo?.length} Volumes Selected
      </Box>

      <TableContainer
        component={Paper}
        sx={{
          border: '1px solid #454546',
          backgroundColor: 'rgba(255, 255, 255, 0.05)',
          borderRadius: '16px',
          marginTop: '15px'
        }}>
        <Table aria-label="collapsible table" sx={{ backgroundColor: 'rgba(31, 32, 35, 0.28)' }}>
          <TableHeader />
          {scheduleModalPropsList?.scheduleInstanceInfo?.map(
            (scheduleInstanceInfo: ScheduleInstanceInfo, idx: number) => (
              <ConfirmOnboardTableRows
                key={scheduleInstanceInfo?.instanceId}
                scheduleInstanceInfo={scheduleInstanceInfo}
                setInstanceInfo={setInstanceInfo}
                instanceInfo={instanceInfo}
              />
            )
          )}
        </Table>
      </TableContainer>
      <Box></Box>
      <Box sx={{ display: 'flex', gap: 3, mt: 3.75, justifyContent: 'flex-end' }}>
        <Buttons
          sx={{
            color: '#ddff55'
          }}
          btnVarient="text"
          onClick={handleModalClose}>
          Back
        </Buttons>
        <Buttons
          data-testid="test-next-btn"
          onClick={handleNextModal}
          sx={{
            minWidth: '110px'
          }}
          disabled={disableBtn}>
          Next
        </Buttons>
      </Box>
    </Box>
  );
};

export default ConfirmScheduleOnboard;
