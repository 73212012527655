import { Box, Typography } from '@mui/material';
import Server from '../../../../../assets/images/server.svg';
import { useAppSelector } from 'store/hooks';
import { FC } from 'react';
import { bothSearchFilterApplied, onlyFilterApplied, onlySearchApplied } from '../../unManagedVm/constant';

interface NovmsMessageProps {
  title?: string;
  subTitle?: string;
  hideSubTtitle?: boolean;
  isUnmanagedVm?:boolean;
}
const NovmsMessage: FC<NovmsMessageProps> = ({ title, subTitle, hideSubTtitle = false,isUnmanagedVm }) => {
  const { isSearchActive } = useAppSelector((state) => state.searchSort);
  const { filters } = useAppSelector((state) => state.unmanagedVm);
  const getUnmanagedVmMessage = () => {
    if (isSearchActive && filters.length === 0) {
      return onlySearchApplied;
    }
    if (!isSearchActive && filters.length > 0) {
      return onlyFilterApplied;
    }
    if (isSearchActive && filters.length > 0) {
      return bothSearchFilterApplied;
    }
    return '';
  };

  const getMessage = () => {
    if (isUnmanagedVm && filters) {
      return getUnmanagedVmMessage();
    }
    return isSearchActive
      ? ' Please adjust your search query and try again. Note: Partial string search is currently not supported.'
      : '';
  };
  return (
    <Box
      sx={{
        border: '1px solid #454546',
        background: 'rgba(255, 255, 255, 0.05)',
        width: '100%',
        p: 4,
        borderRadius: 4
      }}>
      <Box
        sx={{
          my: 4,
          width: '100%',
          textAlign: 'center',
          mx: 'auto'
        }}>
        <img src={Server} alt="server" />
        <Typography variant="h2" gutterBottom fontSize={20} lineHeight="28px">
          {title || 'No results found!'}
        </Typography>
        {!hideSubTtitle && (
          <Typography variant="body2" gutterBottom fontSize={16} lineHeight="24px">
            We could not find the VM that you are looking for.
            {getMessage()}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default NovmsMessage;
