import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { searchSort, searchSortActive, resetSearchSort } from '../store/searchSort.slice';
import { filters, resetPag } from 'features/filter/store';

const useSearch = (cb: any, cb2?: any) => {
  const dispatch = useAppDispatch();
  const { isSearchActive, searchQuery } = useAppSelector((state) => state.searchSort);
  const { requestFilter } = useAppSelector((state) => state.filter);
  const [focus, setFcous] = useState<boolean>(false);
  const [searchQueryLocal, setSearchQueryLocal] = useState<string>('');

  const handleEnterText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQueryLocal(e.target.value)
  };

  const handleFocus = () => {
    setFcous(!focus);
  };
  const handleSearch = (e: any) => {
    e.preventDefault();
    dispatch(searchSort({searchQuery: searchQueryLocal.trim()}))
    if (requestFilter?.pageNo && requestFilter.pageNo > 0) {
      dispatch(filters({ pageNo: 0 }));
      dispatch(searchSortActive());
      return 
    } 
      dispatch(searchSortActive());
  };
  
  const handleSearchTextClear = () => {
    if(!cb2) {
      if (requestFilter?.pageNo && requestFilter.pageNo > 0) {
        dispatch(resetPag());
        dispatch(resetSearchSort({search: true }))
        return
      }
    }

    setSearchQueryLocal('');
    dispatch(resetSearchSort({search: true}))
    if(isSearchActive && cb2) cb2()
  };

  useEffect(() => {
    if(!searchQuery) setSearchQueryLocal('')
  }, [searchQuery])

  return {
    focus,
    setFcous,
    searchQueryLocal,
    setSearchQueryLocal,
    handleEnterText,
    handleFocus,
    handleSearch,
    handleSearchTextClear
  };
};

export { useSearch };
