import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import { InstallCmd, SetupAgentPayload, InstanceListRes, InstanceListPayload, AzureInstanceStatePaylaod, AzureInstanceStateRes } from '../types';

export const getInstallCmdApi = (os: string): Promise<ApiResponse<InstallCmd>> =>
  ApiService.get<InstallCmd>(`/api/v1/agent/install?os=${os}`);

export const getInstanceListApi = (payload: InstanceListPayload): Promise<ApiResponse<InstanceListRes>> =>
  ApiService.get<InstanceListRes>(`/api/v2/instance/cloudVms`, {
    params: {
      pageSize: payload.pageSize,
      pageNumber: payload.pageNumber,
      ...(payload.region ? { region: payload.region } : {}),
      ...(payload.query ? { query: payload.query } : {})
    }
  });

export const setupAgentApi = (payload: SetupAgentPayload[]): Promise<ApiResponse<any>> =>
  ApiService.post<any>(`/api/v1/agent/install`, payload);

export const getAzureInstanceStateListApi = (payload: AzureInstanceStatePaylaod): Promise<ApiResponse<AzureInstanceStateRes>> =>
  ApiService.put<AzureInstanceStateRes>(`/api/v1/instance/azureState`, payload.instanceIds, {
    params: {
      regionName: payload.regionName
    }
  });

