import { Box, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { isRebootRangeValid } from '../../../utils/helper';

interface Props {
  onChange: (minutes: number) => void
  onError: (error: string) => void
}

const RebootTimeRange: FC<Props> = (props) => {
  const [rebootRangeValue, setRebootRangeValue] = useState('0.5');
  const [rebootRangeType, setRebootRangeType] = useState('Hours');
  const [rebootRangeErr, setRebootRangeErr] = useState('');

  useEffect(() => {
    let timeInMinutes = 0;
    if (rebootRangeType === 'Hours') {
      timeInMinutes = Number(rebootRangeValue) * 60;
    } else {
      timeInMinutes = Number(rebootRangeValue) * 60 * 24;
    }

    props.onChange(timeInMinutes)
  }, [rebootRangeType, rebootRangeValue])

  const handleRebootRangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const isValid = isRebootRangeValid(value)
    props.onError('')
    setRebootRangeErr('')

    if (!isValid) {
      const ERROR = 'Please enter a valid number without a decimal. Minimum should be 1/2 hours'
      props.onError(ERROR)
      setRebootRangeErr(ERROR)
    }

    setRebootRangeValue(value);
  };

  const handleRebootRangeType = (e: SelectChangeEvent<string>) => {
    setRebootRangeType(e.target.value);
  };

  return (
    <>
      <Box display="flex">
        <TextField
          type="number"
          variant="outlined"
          value={rebootRangeValue}
          onChange={handleRebootRangeValue}
          error={Boolean(rebootRangeErr)}
          sx={{
            width: '120px',
            textAlign: 'center',
            '& .MuiOutlinedInput-root': {
              borderTopRightRadius: 0,
              borderEndEndRadius: 0
            }
          }}
        />
        <Select
          value={rebootRangeType}
          onChange={handleRebootRangeType}
          variant="outlined"
          error={Boolean(rebootRangeErr)}
          MenuProps={{
            PaperProps: {
              sx: {
                borderRadius: '8px',
                mt: '4px'
              }
            }
          }}
          sx={{
            mb: 1,
            width: '100px',
            '.MuiOutlinedInput-notchedOutline': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0
            }
          }}>
          <MenuItem value="Hours">Hours</MenuItem>
          <MenuItem value="Days">Days</MenuItem>
        </Select>
      </Box>
    </>
  );
};

export default RebootTimeRange;
