import { FC } from 'react';
import { Table } from '@mui/material';
import InnerTableRow from './InnerTableRow';
import InnerTableHead from './InnerTableHead';
import { PartitionDetailsInfo } from 'features/scalar/components/unManagedVm/types';

interface InnerTableProps {
  partitionDetailsInfo: PartitionDetailsInfo[];
  rowStates: boolean[];
  onRowChange: (index: number, isChecked: boolean) => void;
}

const InnerTable: FC<InnerTableProps> = ({ partitionDetailsInfo, rowStates, onRowChange }) => {
  const handleRowToggle = (index: number, isChecked: boolean) => {
    onRowChange(index, isChecked);
  };

  return (
    <Table sx={{ backgroundColor: '#161718' }}>
      <InnerTableHead />
      {partitionDetailsInfo?.map((mountPoint, index) => (
        <InnerTableRow
          key={index}
          mountPoint={mountPoint}
          isChecked={rowStates[index]}
          onCheckboxChange={(isChecked) => handleRowToggle(index, isChecked)}
        />
      ))}
    </Table>
  );
};

export default InnerTable;
