import { ApiService } from 'services/apiServices';
import { ApiResponse } from 'types/response/baseResponse.model';
import { AgentJobsInfoReq, AgentJobsInfoRes } from '../types';

class Api {
  getJobsInfo = (payload: AgentJobsInfoReq): Promise<ApiResponse<AgentJobsInfoRes>> =>
    ApiService.get(`/api/v1/job/info/agentInstall`, {
      params: {
        ...payload
      }
    })
}

export const api = new Api();
