import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AlertComponenet from 'components/common/AlertComponents';
import { toast } from 'react-toastify';
import { AgentJobsInfoReq, AgentInstallInfoSlice } from '../types';

const initialState: AgentInstallInfoSlice = {
  agentInstall: {
    agentInstallData: null,
    status: 'ready'
  }
};

const agentInstallJobsSlice = createSlice({
  name: 'agentInstallJobs',
  initialState,
  reducers: {
    getAIInfoInit: (state, action: PayloadAction<AgentJobsInfoReq>) => {
      state.agentInstall = { status: 'loading', agentInstallData: null };
    },
    getAIInfoSuccess: (state, action) => {
      state.agentInstall = {
        status: 'success',
        agentInstallData: action.payload
      };
    },
    getAIInfoError: (state, action) => {
      state.agentInstall = { status: 'error', agentInstallData: null };
      toast(<AlertComponenet hideHeading msg={action?.payload} toastType="error" />, {
        toastId: 'jobs-agent-install-info-error'
      });
    }
  }
});

export const { getAIInfoInit, getAIInfoSuccess, getAIInfoError } = agentInstallJobsSlice.actions;

export default agentInstallJobsSlice.reducer;
