import CustomToolTip from 'components/StylesTooltip';
import { getMountPointTrim } from '../../../../utils/helper';
import React, { FC } from 'react';
import { Box, Checkbox, TableBody, TableCell, TableRow, Typography } from '@mui/material';

export interface InnerTableRowProps {
  mountPoint: {
    mountPoint: string;
    size: string;
    unrealizedSavings: string;
  };
  isChecked: boolean;
  onCheckboxChange: (isChecked: boolean) => void;
}

const InnerTableRow: FC<InnerTableRowProps> = ({ mountPoint, isChecked, onCheckboxChange }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(event.target.checked);
  };

  return (
    <TableBody>
      <TableRow
        sx={{
          '.MuiTableCell-root': {
            width: 250,
            pl: '2rem'
          },
          '> .MuiTableCell-root': {
            color: 'white'
          },
          borderBottom: '3px solid rgba(255, 255, 255, 0.12)'
        }}>
        <TableCell sx={{ pl: '66px !important', position: 'relative' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CustomToolTip
              style={{ display: 'flex', alignItems: 'center' }}
              left={14}
              top={5}
              right={300}
              showIcon={false}>
              <Checkbox
                sx={{
                  '&.Mui-disabled': {
                    pointerEvents: 'all'
                  }
                }}
                checked={isChecked}
                onChange={handleCheckboxChange}
                data-testid={`value_${mountPoint.mountPoint}`}
              />
              <Typography variant="body2">{getMountPointTrim(mountPoint.mountPoint)}</Typography>
            </CustomToolTip>
          </Box>
        </TableCell>
        <TableCell align="left" sx={{ pl: '2rem' }}>
          <Typography variant="body2" color="white">
            {mountPoint.size}
          </Typography>
        </TableCell>
        <TableCell align="left" sx={{ pl: '2rem' }}>
          <Typography variant="body2" color="white">
            {mountPoint.unrealizedSavings}
          </Typography>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default InnerTableRow;
