import React, { FC, useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import InnerTable from './InnerTable';
import { PartitionDetailsInfo, ScheduleInstanceInfo } from '../../../types';
import VersionChip from '../../diskType/VersionChip';

interface ConfirmOnboardTableRowsProps {
  scheduleInstanceInfo: ScheduleInstanceInfo;
  setInstanceInfo: any;
  instanceInfo: ScheduleInstanceInfo[];
}

const ConfirmOnboardTableRows: FC<ConfirmOnboardTableRowsProps> = ({
  scheduleInstanceInfo,
  setInstanceInfo,
  instanceInfo
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const initialRowStates = scheduleInstanceInfo?.partitionDetailsInfo?.map(() => true) || [];
  const [rowStates, setRowStates] = useState<boolean[]>(initialRowStates);

  const [isOuterChecked, setIsOuterChecked] = useState(() => rowStates.some((state) => state));
  useEffect(() => {
    setIsOuterChecked(rowStates.some((state) => state));
  }, [rowStates]);

  useEffect(() => {
    if (!isOuterChecked) {
      const updatedInstanceInfo = instanceInfo.filter(
        (info) => info.instanceId !== scheduleInstanceInfo.instanceId
      );
      setInstanceInfo(updatedInstanceInfo);
    } else {
      if (!instanceInfo.find((info) => info.instanceId === scheduleInstanceInfo.instanceId)) {
        setInstanceInfo((prev: any) => [...prev, scheduleInstanceInfo]);
      }
    }
  }, [isOuterChecked]);

  const handleOuterCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setRowStates(rowStates.map(() => isChecked));
  };

  const checked = (
    instanceToUpdate: ScheduleInstanceInfo,
    targetMountPoint: string,
    targetPartition: PartitionDetailsInfo
  ) => {
    if (
      !instanceToUpdate.partitionDetailsInfo.some(
        (partition: PartitionDetailsInfo) => partition.mountPoint === targetMountPoint
      )
    ) {
      instanceToUpdate.partitionDetailsInfo.push(targetPartition);
    }

    if (!instanceToUpdate.mountPoint.includes(targetMountPoint)) {
      instanceToUpdate.mountPoint.push(targetMountPoint);
    }
  };
  const unChecked = (
    instanceToUpdate: ScheduleInstanceInfo,
    targetMountPoint: string,
    updatedInstanceInfo: ScheduleInstanceInfo[],
    instanceId: string | undefined
  ) => {
    instanceToUpdate.partitionDetailsInfo = instanceToUpdate.partitionDetailsInfo.filter(
      (partition: PartitionDetailsInfo) => partition.mountPoint !== targetMountPoint
    );
    instanceToUpdate.mountPoint = instanceToUpdate.mountPoint.filter(
      (mount: string) => mount !== targetMountPoint
    );

    if (instanceToUpdate.partitionDetailsInfo.length === 0) {
      const instanceIndex = updatedInstanceInfo.findIndex(
        (instance: ScheduleInstanceInfo) => instance.instanceId === instanceId
      );
      updatedInstanceInfo.splice(instanceIndex, 1);
    }
  };
  const handleRowChange = (index: number, isChecked: boolean) => {
    const updatedStates = [...rowStates];
    updatedStates[index] = isChecked;
    const updatedInstanceInfo = JSON.parse(JSON.stringify(instanceInfo));
    const targetPartition = scheduleInstanceInfo?.partitionDetailsInfo[index];
    const targetMountPoint = targetPartition?.mountPoint;
    const instanceId = scheduleInstanceInfo?.instanceId;
    let instanceToUpdate = updatedInstanceInfo.find(
      (instance: ScheduleInstanceInfo) => instance.instanceId === instanceId
    );

    if (!instanceToUpdate) {
      instanceToUpdate = {
        ...scheduleInstanceInfo,
        partitionDetailsInfo: [],
        mountPoint: []
      };
      updatedInstanceInfo.push(instanceToUpdate);
    }

    if (targetPartition && targetMountPoint) {
      if (isChecked) {
        checked(instanceToUpdate, targetMountPoint, targetPartition);
      } else {
        unChecked(instanceToUpdate, targetMountPoint, updatedInstanceInfo, instanceId);
      }
    }
    setRowStates(updatedStates);
    setInstanceInfo(updatedInstanceInfo);
  };

  return (
    <TableBody>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'none !important', backgroundColor: `rgba(31, 32, 35, 0.28)` }
        }}>
        <TableCell
          align="left"
          sx={{
            paddingLeft: '5px',
            display: 'flex',
            alignItems: 'center'
          }}>
          <Checkbox
            data-testid={'check-box-click' + scheduleInstanceInfo?.instanceName}
            checked={isOuterChecked}
            indeterminate={!isOuterChecked && rowStates.some((state) => state)}
            onChange={handleOuterCheckboxChange}
            sx={{ color: '#DDFF55', '&.Mui-checked': { color: '#DDFF55' } }}
          />
          <Box display={'flex'} gap={'4px'}>
            <Typography variant="body2" fontSize={14}>
              {scheduleInstanceInfo?.instanceName}
            </Typography>
            {scheduleInstanceInfo?.currentDiskRequirementsForV2IconDisplay && (
              <VersionChip
                diskCompatible={scheduleInstanceInfo?.instanceCompatibleForV2Onboard}
              />
            )}
          </Box>
        </TableCell>
        <TableCell align="left">{scheduleInstanceInfo?.osShortName}</TableCell>
        <TableCell align="center">{scheduleInstanceInfo?.mountPoint.length}</TableCell>
        <TableCell
          align="center"
          sx={{ borderLeft: '1px solid rgba(221, 255, 82, 0.08)' }}
          onClick={() => setOpen(!open)}
          data-testid={
            scheduleInstanceInfo?.instanceName + '_' + scheduleInstanceInfo?.osShortName
          }>
          <IconButton
            size="small"
            sx={{
              borderRadius: 99,
              backgroundColor: 'rgba(221, 255, 85, 0.08)',
              color: '#DDFF55'
            }}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout={10} unmountOnExit>
            <InnerTable
              partitionDetailsInfo={scheduleInstanceInfo?.partitionDetailsInfo}
              rowStates={rowStates}
              onRowChange={handleRowChange}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export default ConfirmOnboardTableRows;
