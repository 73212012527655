import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { Box, Divider, Typography } from '@mui/material';
import Buttons from 'components/buttons';
import { StyledMenu } from 'components/common/styledMUIcomp/StyledMenu';
import { reusableStyles } from 'constants/style';
import { jobsAnalytics } from 'features/jobs/utils/analytics';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { shouldShowAnnouncement } from 'utils/announcement';
import storage from 'utils/storage';

const JOBS_ICON_FLASHING_ANNOUCEMENT_ID = 'jobs-icon-flashing'

const JobsMenu = () => {
  const { showNotificationIndicator } = useAppSelector((state) => state.jobs.jobsShared);
  const { pathname } = useLocation();
  const navigation = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    storage.setAnnouncementFirstShown(JOBS_ICON_FLASHING_ANNOUCEMENT_ID)
  }, [])

  const handleJobsMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    if (pathname === '/jobs') return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleViewJobs = () => {
    jobsAnalytics.featuredAccessed({
      accessed_from_page: window.location.href,
      source: 'navigation menu'
    })
    storage.setAnnouncementClicked(JOBS_ICON_FLASHING_ANNOUCEMENT_ID);
    setAnchorEl(null);
    navigation('jobs');
  };

  return (
    <>
      <Box
        sx={{
          animation: shouldShowAnnouncement(JOBS_ICON_FLASHING_ANNOUCEMENT_ID) ? 'blink 1.5s infinite' : undefined,
          position: 'relative',
          width: '40px',
          height: '40px',
          border: '1px solid rgba(221, 255, 85, 0.08)',
          borderRadius: 99,
          backgroundColor: 'rgba(221, 255, 85, 0.08)',
          ...reusableStyles?.centerContent
        }}
        id="jobs-menu"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleJobsMenu}>
        <PlaylistAddCheckIcon
          sx={{
            '&.MuiSvgIcon-root': {
              color: pathname === '/jobs' ? '#DF5' : '#fff'
            }
          }}
        />
        {showNotificationIndicator && (
          <Box
            sx={{
              aspectRatio: '1',
              width: '12px',
              border: '2px solid black',
              backgroundColor: '#E15D5D',
              borderRadius: '50%',
              position: 'absolute',
              top: '0',
              right: '0'
            }}
          />
        )}
      </Box>
      <StyledMenu
        sx={{
          width: 304,
          '& .MuiBackdrop-root': { backgroundColor: 'rgba(22, 23, 24, 0.90) !important' }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: -240
        }}
        MenuListProps={{
          'aria-labelledby': 'jobs-menu'
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <Box
          sx={{
            padding: '16px 16px 0px 16px'
          }}>
          <Box
            sx={{
              ...reusableStyles.spaceBtwnContent,
              alignItems: 'flex-start'
            }}>
            <Typography variant="h3" fontSize={16} mb={1}>
              Jobs
            </Typography>
            <CloseIcon
              onClick={handleClose}
              sx={{
                fontSize: '16px',
                cursor: 'pointer'
              }}
            />
          </Box>

          <Typography variant="body2" fontSize={14}>
            Track your scheduled Onboardings and agent installations under Lucidity jobs feature.
          </Typography>
        </Box>
        <Divider sx={{ mt: 2 }} />
        <Box
          sx={{
            padding: '16px'
          }}>
          <Buttons
            sx={{ marginLeft: 'auto', display: 'flex', padding: '4px 20px' }}
            onClick={handleViewJobs}>
            View Jobs
          </Buttons>
        </Box>
      </StyledMenu>
    </>
  );
};

export default JobsMenu;
