import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

const tableHeader = ['Volume', 'Size', 'Savings'];

const getPadding = (index: number) => {
  if (index === 0) return '3.75rem';
  else return '2rem';
};

const InnerTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {tableHeader.map((header: string, index: number) => (
          <TableCell
            align="left"
            key={`${header + index}`}
            sx={{
              pl: getPadding(index),
              py: '16px',
              width: `${index === 0 ? '330px' : 'auto'}`
            }}>
            <Typography
              variant="body1"
              fontSize={12}
              sx={{ textTransform: 'uppercase', color: 'rgba(255, 255, 255, 0.5)' }}>
              {header}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default InnerTableHead;
