import { createSlice } from '@reduxjs/toolkit';
import { toast as toasts } from 'react-toastify';
import AlertComponenet from 'components/common/AlertComponents';
import { KubernetesSliceType } from 'features/kubernetes/types';

const initialState: KubernetesSliceType = {
  kubernetesSaving: {
    kubernetesSavingData: undefined,
    status: 'ready'
  },
  simplifyPvCreation: undefined,
  clusterListData: undefined,
  describeClusterData: undefined,
  inProcess: undefined,
  inProcessUnmanaged: undefined,
  clusterState: undefined,
  listPvData: undefined,
  onboardApiCompletion: undefined,
  onboardPvData: undefined,
  deployYmlData: undefined,
  inProcessYML: undefined
};

const kubernetesSlice = createSlice({
  name: 'kubernetes',
  initialState,
  reducers: {
    setClusterState: (state, action) => {
      state.clusterState = action.payload;
    },
    getKubernetesSaving: (state, { payload }) => {
      state.kubernetesSaving.status = "loading";
    },
    getKubernetesSavingFulfilled: (state, action) => {
      state.kubernetesSaving.kubernetesSavingData = action.payload;
      state.kubernetesSaving.status="success"
    },
    getKubernetesSavingRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'Kubernetessaving-error' });
      state.kubernetesSaving.status="error"
      state.kubernetesSaving.kubernetesSavingData= undefined;
    },
    getSimplifyPvCreation: (state) => {
      state.inProcess = true;
    },
    getSimplifyPvCreationFulfilled: (state, action) => {
      state.simplifyPvCreation = action.payload;
      state.inProcess = false;
    },
    getSimplifyPvCreationRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'SimplifyPv-error' });
      state.inProcess = false;
      state.simplifyPvCreation = undefined;
    },
    getClusterList: (state, { payload }) => {
      state.inProcessUnmanaged = true;
    },
    getClusterListFulfilled: (state, action) => {
      state.clusterListData = action.payload;
      state.inProcessUnmanaged = false;
    },
    getClusterListRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'KubernetesClusterList-error' });
      state.inProcessUnmanaged = false;
      state.clusterListData = undefined;
    },
    getdescribeCluster: (state, { payload }) => {
      state.inProcessUnmanaged = true;
    },
    getdescribeClusterFulfilled: (state, action) => {
      state.describeClusterData = action.payload;
      state.inProcessUnmanaged = false;
      state.clusterState = action.payload?.data?.clusterState
    },
    getdescribeClusterRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'DescribeCluster-error' });
      state.inProcessUnmanaged = false;
      state.describeClusterData = undefined;
    },
    getListPv: (state, { payload }) => {
      state.inProcessUnmanaged = true;
      state.onboardApiCompletion = false;
    },
    getListPvFulfilled: (state, action) => {
    state.listPvData = action.payload;
    state.inProcessUnmanaged = false;
    },
    getListPvRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'ListPV-error' });
      state.inProcessUnmanaged = false;
      state.listPvData = undefined;
    },
    getOnboard: (state, { payload }) => {
      state.onboardApiCompletion= false;
    },
    getOnboardPvFulfilled: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload?.message} hideHeading toastType='success' />, { toastId: 'Onboard-success' });
      state.onboardPvData = action.payload;
      state.onboardApiCompletion = true;
    },
    getOnboardPvRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'Onboard-error' });
      state.onboardPvData = undefined;
      state.onboardApiCompletion = true;
    },
    getDeployYml: (state, { payload }) => {
      state.inProcessYML = true;
    },
    getDeployYmlFulfilled: (state, action) => {
      state.deployYmlData = action.payload;
      state.inProcessYML = false;
    },
    getDeployYmlRejected: (state, action) => {
      toasts(<AlertComponenet msg={action?.payload} />, { toastId: 'DeployYml-error' });
      state.inProcessYML = false;
      state.deployYmlData = undefined;
    }
  }
});

export const {
  setClusterState,
  getKubernetesSaving,
  getKubernetesSavingFulfilled,
  getKubernetesSavingRejected,
  getSimplifyPvCreation,
  getSimplifyPvCreationFulfilled,
  getSimplifyPvCreationRejected,
  getClusterList,
  getClusterListFulfilled,
  getClusterListRejected,
  getdescribeCluster,
  getdescribeClusterFulfilled,
  getdescribeClusterRejected,
  getListPv,
  getListPvFulfilled,
  getListPvRejected,
  getOnboard,
  getOnboardPvFulfilled,
  getOnboardPvRejected,
  getDeployYml,
  getDeployYmlFulfilled,
  getDeployYmlRejected,
} = kubernetesSlice.actions;

export default kubernetesSlice.reducer;
