import { add, format, setSeconds, sub } from 'date-fns';
import { epochToLocalDate } from 'utils/common';
import {
  BulkOnboardInstancesInfo,
  BulkOnboardSchedulePayload,
  FiltersPayloadType,
  PartitionDetailsInfo,
  Partitions,
  PlannedInstanceInfo,
  ScheduleInstanceInfo,
  ScheduleModalProps,
  UnManagedVmsRes
} from '../types';

export const isRebootRangeValid = (value: string) => {
  const haveNonDecimalValue = /^(0\.5|[1-9]\d*|0)$/;

  if (!haveNonDecimalValue.test(value) || value === '' || value === '0') return false;

  return true;
};

export const getPartitionDetailsInfo = (instance: PlannedInstanceInfo) => {
  return instance.mountPointInfos.map((mp) => ({
    mountPoint: mp.mountPoint,
    size: mp.size,
    minimumHoursForLinuxMigration: mp.minimumHoursForLinuxMigration
  }));
};

const formatDate = (date: Date | number) => {
  return format(date, 'MMM d, yyyy, h:mm a');
}

export const ONE_HOUR_WAIT_TIME = 1;
export const VD_CREATION_TIME = 1;
export const BUFFER_TIME = 3;

export const getTotalHoursForEachInstance = (scheduleInstanceInfo: ScheduleInstanceInfo[]) => {
  const timeForEachInstance: Record<string, number> = {};

  for (const instance of scheduleInstanceInfo) {
    const totalTime = Object.values(instance.partitionDetailsInfo).reduce(
      (acc, curr) => acc + (curr.minimumHoursForLinuxMigration || 0),
      0
    );

    timeForEachInstance[instance.instanceId] = totalTime;
  }

  return timeForEachInstance;
};

export const getInstanceWithMaxTime = (timeForEachInstance: Record<string, number>) => {
  let maxTime = -Infinity;
  let maxInstance = '';

  for (const instanceId in timeForEachInstance) {
    const time = timeForEachInstance[instanceId];
    if (time > maxTime) {
      maxTime = time;
      maxInstance = instanceId;
    }
  }

  return { instanceId: maxInstance, time: maxTime };
};

export const truncInstanceId = (instanceId: string) => {
  const segments = instanceId.split('/')

  if (segments.length === 1) return instanceId

  const initialSegments = segments.slice(0, segments.length - 1).join('/').slice(0, 20)
  const lastSegment = segments.at(-1)

  return `${initialSegments}.../${lastSegment}`
}

export const getFirstOnboardingInfoMsg = (
  scheduleInstanceInfo: ScheduleInstanceInfo[],
  startTime: Date
) => {
  const totalHoursForEachInstance = getTotalHoursForEachInstance(scheduleInstanceInfo);
  const instanceWithMaxTime = getInstanceWithMaxTime(totalHoursForEachInstance);
  const firstOnboardingTime = sub(startTime, {
    hours: instanceWithMaxTime.time + VD_CREATION_TIME + BUFFER_TIME
  });
  const formattedDate = formatDate(firstOnboardingTime)
  const truncatedInstanceId = truncInstanceId(instanceWithMaxTime.instanceId)

  return `First instance onboarding begins: ${formattedDate} for Instance ID ${truncatedInstanceId}`;
};

export const getRebootWindowInfoMsg = (startTime: Date) => {
  const rebootWindowStart = formatDate(startTime);
  const rebootWindowEnd = formatDate(add(startTime, { minutes: 30 }));

  return `Reboot window: ${rebootWindowStart}–${rebootWindowEnd} for all instances in this batch.`;
};

export const getRebootRetriesInfoMsg = (startTime: Date, rebootWindowInHours: number) => {
  const rebootRetriesTillDate = formatDate(add(startTime, { hours: rebootWindowInHours }));

  return `Missed reboot? Reboot retries automatically until ${rebootRetriesTillDate}`;
};

export const getLinuxNonRootInfoMsg = (
  scheduleInstanceInfo: ScheduleInstanceInfo[],
  startTime: Date,
  rebootWindowInHours: number
) => {
  const firstOnboardingInfoMsg = getFirstOnboardingInfoMsg(scheduleInstanceInfo, startTime);
  const rebootWindowInfoMsg = getRebootWindowInfoMsg(startTime);
  const rebootRetriesInfoMsg = getRebootRetriesInfoMsg(startTime, rebootWindowInHours);
  const onboardingOrderInfoMsg =
    'Onboarding order: Instances are processed based on data size, starting with the largest.';

  return [
    firstOnboardingInfoMsg,
    rebootWindowInfoMsg,
    rebootRetriesInfoMsg,
    onboardingOrderInfoMsg
  ];
};

export const getMinScheduleDateForLinux = (scheduleModalProps: ScheduleModalProps) => {
  const totalHoursForEachInstance = getTotalHoursForEachInstance(
    scheduleModalProps.scheduleInstanceInfo
  );
  const instanceWithMaxTime = getInstanceWithMaxTime(totalHoursForEachInstance);
  return add(new Date(), {
    hours: instanceWithMaxTime.time + VD_CREATION_TIME + BUFFER_TIME + ONE_HOUR_WAIT_TIME
  });
};

export const getMinDateForSchedule = (scheduleModalProps: ScheduleModalProps | null): Date => {
  if (!scheduleModalProps) return new Date();

  const { minimumTimeBeforeReboot, customRebootBasedOnboard, platformType } = scheduleModalProps;

  if (minimumTimeBeforeReboot && customRebootBasedOnboard) {
    if (platformType === 'Linux') return getMinScheduleDateForLinux(scheduleModalProps);

    return setSeconds(epochToLocalDate(minimumTimeBeforeReboot), 0);
  }

  return new Date();
};

export const isSetMinDate = (date: string, scheduleModalProps: ScheduleModalProps | null) => {
  if (
    scheduleModalProps &&
    scheduleModalProps?.minimumTimeBeforeReboot &&
    date &&
    scheduleModalProps?.customRebootBasedOnboard
  ) {
    const selectedDate = format(new Date(date), 'yyyy-MM-dd');
    const minReebootDate = scheduleModalProps.platformType === 'Linux' 
    ? getMinScheduleDateForLinux(scheduleModalProps)
    : epochToLocalDate(scheduleModalProps?.minimumTimeBeforeReboot)

    const formattedDate = format(minReebootDate, 'yyyy-MM-dd') 
    
    if (selectedDate !== formattedDate) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getMinimumHoursBeforeReboot = (minTimeBeforeReboot?: number) => {
  if (minTimeBeforeReboot) {
    const currentTime = new Date().getTime() / 1000;
    const rebootTime = minTimeBeforeReboot;
    const hoursUntilReboot = (rebootTime - currentTime) / 3600;
    return Math.ceil(hoursUntilReboot);
  }
  return 1;
};

export const getNumberOfInstanceFromPyaload = (payload: BulkOnboardSchedulePayload) => {
  const uniqueInstanceId = new Set();
  payload.mountPointInfo?.map((instanceInfo: BulkOnboardInstancesInfo) => {
    uniqueInstanceId.add(instanceInfo?.instanceId);
  });
  return uniqueInstanceId.size;
};

export const getFiltersApplied = (unmanagedVmFilters: FiltersPayloadType[]) => {
  const filtersApplied: string[] = [];
  unmanagedVmFilters.forEach((unmanagedVmFilter: FiltersPayloadType) => {
    if (unmanagedVmFilter?.value) {filtersApplied.push(unmanagedVmFilter.value);}
  });
  return filtersApplied;
};

export const getCurrentLocalTimestamp = (): string => {
  return new Date().toLocaleString();
};



export const isDiskConversionCompatible = (selectedInsatance: UnManagedVmsRes) => {
  return selectedInsatance?.currentDiskRequirementsForV2IconDisplay;
};

export const addSecondsToEpoch = (
  epochTime: number | undefined,
  secondsToAdd: number
): number | undefined => {
  if (epochTime === undefined) return undefined;

  return epochTime + secondsToAdd;
};

export const getMountPointTrim = (mp: string) => {
  if (mp?.length > 28) {
    return mp.slice(0, 28) + '...';
  }
  return mp;
};
export const filterTabType = {
  STATUS: 'STATUS',
  VOLUME: 'VOLUME',
  OS: 'OS'
}

export const filterList = {
  STATUS: [
    'Ready to Onboard',
    'In Progress',
    'Onboard Scheduled',
    'Not Supported',
    'Failed to Onboard'
  ],
  VOLUME: [
    'data',
    'root'
  ]
}

export const calculateTotalSavingFromScheduleModalPropsList = (instance: ScheduleInstanceInfo) => {
  let totalSaving = 0;
  instance?.partitionDetailsInfo.forEach((partitionDetails: PartitionDetailsInfo) => {
    if (instance?.anyPartitionOnboardedToV2 &&
      partitionDetails?.unrealizedV2OnboardingSavings) {
      totalSaving += partitionDetails?.unrealizedV2OnboardingSavings;
    }
    else {
      totalSaving += Number(partitionDetails?.unrealizedSavings.slice(1))
    }
  });
  return totalSaving;
}

export const calculateTotalSavingFromScheduleModalPropsListWithConversion = (instance: ScheduleInstanceInfo) => {
  let totalSavingWithConversion = 0;
  instance?.partitionDetailsInfo.forEach((partitionDetails: PartitionDetailsInfo) => {

    if (instance?.v2OnboardingFeatureEnabled &&
      instance?.instanceCompatibleForV2Onboard &&
      instance?.currentDiskRequirementsForV2IconDisplay) {
      totalSavingWithConversion+= partitionDetails?.unrealizedV2OnboardingSavings;
    }
    else{
      totalSavingWithConversion+=Number(partitionDetails?.unrealizedSavings.slice(1))
    }

  });
  return totalSavingWithConversion;
}

export const generatePartitionDetailsInfo = (selectedPartitionInfo: Partitions | Partitions[]) => {
  const partitions = Array.isArray(selectedPartitionInfo)
    ? selectedPartitionInfo
    : [selectedPartitionInfo];
  return partitions?.map((partition) => ({
    mountPoint: partition?.mountPoint,
    unrealizedSavings: partition?.unrealizedSavings,
    unrealizedV2OnboardingSavings: partition?.unrealizedV2OnboardingSavings?.amount,
    size: partition?.size,
    minimumHoursForLinuxMigration: partition.minimumHoursForLinuxMigration
  }));
};