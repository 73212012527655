import storage from "./storage";

export const shouldShowAnnouncement = (announcementId: string) => {
  const announcementClicked = storage.getAnnoucementClicked(announcementId)
  const announcementFirstShownTime = storage.getAnnouncementFirstShown(announcementId)
  return !announcementClicked && !isSevenDaysPassed(announcementFirstShownTime)
}

const isSevenDaysPassed = (timestamp: number): boolean => {
  const now = Date.now();
  const sevenDaysInMs = 7 * 24 * 60 * 60 * 1000;
  return now - timestamp >= sevenDaysInMs;
};