import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { checkTenantLenghtInFilter } from 'utils/common';
import {
  getUnManagedVm,
  resetBulkOnboardSchedulePayload,
  setModalPreference,
  setScheduleModalProps
} from '../store';
import { FiltersPayloadType } from '../types';

const useUnManagedVm = () => {
  const dispatch = useAppDispatch();
  const tenantData = useAppSelector((state) => state.tenants);
  const { requestFilter } = useAppSelector((state) => state.filter);
  const { filterQuery, searchQuery } = useAppSelector((state) => state.searchSort);
  const { status, bulkSchedule, filters: unmanagedVmFilters } = useAppSelector((state) => state.unmanagedVm);

  const generatePayloadForSearchQuery = (searchQueryArray: string[]): FiltersPayloadType[] => {
    return searchQueryArray?.map((element) => ({
      type: 'QUERY',
      value: element
    }));
  };

  const createCsvList = (query: string): string[] => {
    if (!query || query.trim() === '') return [];
    return query.split(',').map((value) => value.trim());
  };

  const unmnagedVmApiCall = () => {
    const searchQueryList = createCsvList(searchQuery);
    const searchQueryForPayload = generatePayloadForSearchQuery(searchQueryList);
    const updatedUnmangedVmFilters = [...unmanagedVmFilters, ...searchQueryForPayload];
    dispatch(
      getUnManagedVm({
        filter: { ...requestFilter, unmanagedVmFilters: updatedUnmangedVmFilters },
        query: '',
        unmanagedVmFilters,
        ...filterQuery
      })
    );
  };

  useEffect(() => {
    if (
      !tenantData?.inProcess &&
      tenantData?.inProcess !== undefined &&
      checkTenantLenghtInFilter(requestFilter) > 0
    ) {
      if (filterQuery?.sortUnvmQuery || filterQuery?.rebootQuery || searchQuery || unmanagedVmFilters) {
        unmnagedVmApiCall();
      } else {
        dispatch(getUnManagedVm({ filter: requestFilter }));
      }
    }
  }, [requestFilter, filterQuery, searchQuery, unmanagedVmFilters]);

  useEffect(() => {
    if (bulkSchedule?.status === 'success' && checkTenantLenghtInFilter(requestFilter) > 0) {
      unmnagedVmApiCall();
    }
    if (bulkSchedule?.status !== 'loading') {
      dispatch(setModalPreference(null));
      dispatch(resetBulkOnboardSchedulePayload());
      dispatch(setScheduleModalProps(null));
    }
  }, [bulkSchedule]);

  return {};
};

export default useUnManagedVm;
