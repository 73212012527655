import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { GlobalBarLoader } from 'components/GlobalSpinner';
import { SearchBox, SortBox } from 'components/searchAndSort';
import FilterModal from './FilterModal';
import SortIcon from '@mui/icons-material/Sort';
import { FilterList } from '@mui/icons-material';
import { useAppSelector } from 'store/hooks';
import { filterKeys } from 'constants/filter';

const style = {
  display: 'flex',
  gap: 2,
  alignItems: 'center',
  position: 'relative'
};

const SearchSortUmvm = () => {
  const { unmanagedVmsData, status, filters } = useAppSelector((state) => state.unmanagedVm);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFilterBtn = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        ...style,
        justifyContent: 'space-between',
        mb: 3
      }}>
      <Box
        sx={{
          ...style
        }}>
        <Typography variant="h2" fontSize={20} lineHeight="28px" sx={{ minWidth: 30, ...style }}>
          {status === 'loading' ? (
            <GlobalBarLoader sx={{ width: '20px' }} />
          ) : (
            unmanagedVmsData?.meta?.totalItems || 0
          )}{' '}
          Instances
        </Typography>
        <Box
          sx={{
            width: '1px',
            height: '24px',
            background: '#454546'
          }}></Box>
        <Box
          sx={{
            ...style,
            gap: 2,
            alignItems: 'center'
          }}>
          <SearchBox
            toolTipText={'You can search multiple values by entering them comma-separated'}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 1
        }}>
        <Box
          sx={{
            border: '1px solid var(--Black-Divider, #454546)',
            color: filters?.length ? 'rgba(221, 255, 85, 1)' : '',
            backgroundColor: filters?.length ? 'rgba(221, 255, 85, 0.2)' : 'rgba(255, 255, 255, 0.05)',
            px: 2,
            py: 1,
            borderRadius: '12px',
            cursor: 'pointer',
            padding: '7px 16px'
          }}
          onClick={handleFilterBtn}>
          <Box display="flex" gap={1} >
            <FilterList sx={{ fontSize: '18px', margin: '3px 0' }} />
            <Typography
              sx={{ fontSize: '16px', fontWeight: '400', lineHeight: '24px', font: 'inherit' }}
              align="center"
              color={filters?.length ? 'rgba(221, 255, 85, 1)' : ''}>
              Filter
            </Typography>
          </Box>
        </Box>
        <SortBox filterKey={filterKeys.sortUnvm} />
      </Box>
      {anchorEl && <FilterModal handleClose={handleClose} anchorEl={anchorEl} />}
    </Box>
  );
};

export default SearchSortUmvm;
