import { FC, useEffect } from 'react';
import { Search, SearchIconWrapper, CloseIconWrapper, StyledInputBase } from '../style/MuiStyle';
import WhiteSearch from 'assets/images/searchAndSort/white_search.svg';
import GraySearch from 'assets/images/searchAndSort/grey_search.svg';
import Close from 'assets/images/searchAndSort/close.svg';
import { useSearch } from 'components/searchAndSort/hook/useSearch';
import { SearchType } from '../types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetSearchSort } from '../store/searchSort.slice';
import { SimpleToolTip } from 'components/StylesTooltip';

const SearchBox:FC<SearchType> = ({ cb, placeholder, cb2 ,toolTipText}) => {
  const {
    focus,
    handleEnterText,
    handleFocus,
    handleSearch,
    handleSearchTextClear,
    searchQueryLocal
  } = useSearch(cb, cb2);
  const dispatch = useAppDispatch()
  const {searchQuery} = useAppSelector(state => state.searchSort)

  useEffect(() => {
    return () => {
      dispatch(resetSearchSort({both: true}));
    }
  },[])
  const searchBoxContainer = () => {
    return (
      <form onSubmit={handleSearch}>
        <Search focus={focus}>
          <SearchIconWrapper onClick={handleSearch}>
            <img
              src={focus ? WhiteSearch : GraySearch}
              alt={focus ? 'WhiteSearch' : 'GraySearch'}
            />
          </SearchIconWrapper>
          <StyledInputBase
            onChange={handleEnterText}
            onFocus={handleFocus}
            onBlur={handleFocus}
            placeholder={
              placeholder
                ? placeholder
                : 'Search by Instance id, name or resource-group (azure only)'
            }
            inputProps={{ 'aria-label': 'search' }}
            value={searchQueryLocal}
          />
          {searchQuery && (
            <CloseIconWrapper onClick={handleSearchTextClear}>
              <img src={Close} alt="Close" />
            </CloseIconWrapper>
          )}
        </Search>
      </form>
    );
  };

  return (
    toolTipText?(
    <SimpleToolTip 
      text={toolTipText}
      showIcon={false}
      placement="right"
      style={{width:'275px'}}
    >
     {searchBoxContainer()}
    </SimpleToolTip> ) : (searchBoxContainer())
  );
};

export default SearchBox;
