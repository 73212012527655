import { Box, styled, Typography } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import React, { FC } from 'react';
import TooltipImg from '../../assets/images/info.svg';
import TooltipImgPrimary from '../../assets/images/Info_primary.svg';
import PrimaryQ from '../../assets/images/primaryQ.svg';
import './style.scss';

const commonStyle = {
  backgroundColor: 'rgba(31, 32, 35, 0.28)',
  color: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(32.5px)',
  fontSize: '16px',
  lineHeight: '22px',
  padding: '12px 16px 16px',
  borderRadius: '16px',
  border: '1px solid rgba(255, 255, 255, 0.2)'
};

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(221, 255, 82, 0.08)',
    '&::before': {
      border: '1px solid rgba(255, 255, 255, 0.2)',
      backgroundColor: 'rgba(31, 32, 35, 0.28)'
    }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ...commonStyle,
    maxWidth: '330px'
  }
}));

export interface CustomToolTipProp {
  text?: string | React.ReactNode;
  right?: number | string;
  top?: number;
  left?: number | string;
  children?: React.ReactNode;
  title?: string;
  ttOpen?: any;
  handleTTClose?: (e: any) => void;
  handleTTOpen?: (e: any) => void;
  showIcon?: boolean;
  component?: any;
  iconType?: 'grayQ' | 'primaryE' | 'primaryQ';
  iconImgStyle?: { [x: string]: string };
  disable?: boolean;
  style?: any;
  arrow?: boolean;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start',
  dataTestId?: string;
}

const CustomToolTip: FC<CustomToolTipProp> = ({
  children,
  text,
  right,
  top,
  showIcon = true,
  component,
  left = 'auto',
  iconType = 'grayQ',
  iconImgStyle = { height: '18px', width: '18px' },
  disable = false,
  style,
  dataTestId

}) => {
  return (
    <StyledTooltip
      title={text ? text : component}
      disableHoverListener={disable}
      disableTouchListener={disable}>
      <Box
        data-testid={`custom-tooltip-${typeof text === 'string' ? text : 'loaded'}`}
        className="tooltip"
        sx={{
          ...style,
          right: `${right ? (right === 'auto' ? 'auto' : right + 'px') : 15 + 'px'} !important`,
          top: `${top ? top : 15}px !important`,
          left: left,
          '& img': {
            height: { ...iconImgStyle }
          }
        }}>
        {showIcon && (
          <>
            {iconType === 'grayQ' && <img data-testid={dataTestId} src={TooltipImg} alt="server" />}
            {iconType === 'primaryE' && <img data-testid={dataTestId} src={TooltipImgPrimary} alt="server" />}
            {iconType === 'primaryQ' && <img data-testid={dataTestId} src={PrimaryQ} alt="server" />}
          </>
        )}
        {children}
      </Box>
    </StyledTooltip>
  );
};

export default CustomToolTip;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="left" />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(255, 255, 255, 0.6)',
    backgroundColor: 'rgba(31, 32, 35, 0.28)',
    '&::before': {
      border: '1px solid rgba(255, 255, 255, 0.2)',
      backgroundColor: 'rgba(31, 32, 35, 0.28)'
    }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ...commonStyle,
    marginLeft: '0 !important'
  }
}));

export const CustomLayoutToolTip: FC<CustomToolTipProp> = ({
  text,
  title,
  ttOpen,
  handleTTClose,
  handleTTOpen,
  children
}) => {
  return (
    <HtmlTooltip
      open={ttOpen}
      onClose={handleTTClose}
      onOpen={handleTTOpen}
      title={
        <>
          <Typography variant="h5" gutterBottom fontSize={16} lineHeight="22px" sx={{ mb: 0.25 }}>
            {title}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            fontSize={16}
            lineHeight="22px"
            sx={{ mb: 0.25, color: 'rgba(255, 255, 255, 0.6)' }}>
            {text}
          </Typography>
        </>
      }
      placement="right">
      <Box>{children}</Box>
    </HtmlTooltip>
  );
};

export const SimpleTooltipStyle = styled(({ className, arrow, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow={arrow} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(221, 255, 82, 0.08)',
    '&::before': {
      border: '1px solid rgba(255, 255, 255, 0.2)',
      backgroundColor: 'rgba(31, 32, 35, 0.28)'
    }
  },
  [`& .${tooltipClasses.tooltip}`]: {
    ...commonStyle,
    maxWidth: '100%'
  }
}));

export const SimpleToolTip: FC<CustomToolTipProp> = ({
  children,
  text,
  style,
  showIcon = true,
  iconType = 'grayQ',
  iconImgStyle = { height: '18px', width: '18px' },
  disable = false,
  arrow = true,
  placement="top",
  ...props
}) => {
  return (
    <SimpleTooltipStyle
      arrow={arrow}
      title={text}
      disableHoverListener={disable}
      disableTouchListener={disable}
      placement={placement}
      {...props}
      sx={{
        '& .MuiTooltip-tooltip': {
          maxWidth: '330px',
          ...style
        }
      }}>
      <Box data-testid={`simple-custom-tooltip-${typeof text === 'string' ? text : 'loaded'}`}>
        {showIcon && (
          <>
            {iconType === 'grayQ' && <img src={TooltipImg} alt="server" />}
            {iconType === 'primaryE' && <img src={TooltipImgPrimary} alt="server" />}
            {iconType === 'primaryQ' && <img src={PrimaryQ} alt="server" />}
          </>
        )}
        {children}
      </Box>
    </SimpleTooltipStyle>
  );
};
