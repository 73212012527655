import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Box, Typography } from '@mui/material';
import Bell from 'assets/images/bell_primary.svg';
import Buttons from 'components/buttons';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setModalPreference } from '../../../store';

interface PreOnboardDisclaimerModalProps {
  instance?: any;
}
interface ContentProps {
  heading: string;
  subHeading: string;
}
const Content: FC<ContentProps> = ({ heading, subHeading }) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
      <KeyboardDoubleArrowRightIcon color="primary" />
      <Box>
        <Typography variant="h3" fontSize={16} lineHeight="24px">
          {heading}
        </Typography>
        <Typography variant="body2" fontSize={14} lineHeight="20px">
          {subHeading}
        </Typography>
      </Box>
    </Box>
  );
};

const PreOnboardingWin2012Message: FC<PreOnboardDisclaimerModalProps> = () => {
  const dispatch = useAppDispatch();
  const { scheduleModalPropsList } = useAppSelector((state) => state.unmanagedVm);
  const handleModalClose = () => {
    dispatch(setModalPreference('confirmOnboard'));
  };
  return (
    <Box
      sx={{
        padding: '10px'
      }}>
      <Typography
        variant="h2"
        gutterBottom
        fontSize={20}
        lineHeight="28px"
        mb={1}
        sx={{ display: 'flex', gap: 1 }}>
        <img src={Bell} alt="Bell" />
        {scheduleModalPropsList?.popupInfo?.heading}
      </Typography>
      <Box
        sx={{
          background: 'rgba(255, 255, 255, 0.09)',
          borderRadius: 6,
          padding: '18px',
          mt: 4
        }}>
        {scheduleModalPropsList?.popupInfo?.infoItems?.map((infos: any) => (
          <Content key={infos.title} heading={infos.title} subHeading={infos.message} />
        ))}
      </Box>
      <Buttons
        sx={{ mt: 2, ml: 'auto', display: 'flex' }}
        onClick={handleModalClose}
        text="Got it"></Buttons>
    </Box>
  );
};

export default PreOnboardingWin2012Message;
