import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import AlertComponenet from 'components/common/AlertComponents';
import { toast } from 'react-toastify';
import { JobsListyReq, JobsListyRes, JobsSharedSliceType, JobDetail, SummaryReq } from '../types';
import { ApiResponse } from 'types/response/baseResponse.model';

const initialState: JobsSharedSliceType = {
  summary: {
    summaryData: null,
    status: 'ready'
  },
  showNotificationIndicator: false,
  jobsList: {
    scheduledInProgress: {
      scheduledInProgressData: null,
      status: 'ready'
    },
    completed: {
      completedData: null,
      status: 'ready'
    }
  },
  jobDetail: { jobStatus: undefined, jobsId: -1, name: '', onboardJobUpdateTrigger: false },
  tabValue: 0
};

const jobsSharedSlice = createSlice({
  name: 'jobsShared',
  initialState,
  reducers: {
    setJobsDetail: (state, action: PayloadAction<JobDetail | null>) => {
      if (!action.payload) {
        state.jobDetail = {
          jobStatus: undefined,
          jobsId: -1,
          name: '',
          onboardJobUpdateTrigger: false
        };
      } else {
        state.jobDetail = action.payload;
      }
    },
    setNotificationIndicator: (state, action: PayloadAction<boolean>) => {
      state.showNotificationIndicator = action.payload;
    },
    setJobTabValue: (state, action: PayloadAction<number>) => {
      state.tabValue = action.payload;
    },
    resetAllSchedule: (state) => {
      state.summary = { status: 'ready', summaryData: null };
      state.jobsList.scheduledInProgress = { status: 'ready', scheduledInProgressData: null };
      state.jobsList.completed = { status: 'ready', completedData: null };
    },

    getSummaryInit: (state, action: PayloadAction<SummaryReq>) => {
      state.summary = { status: 'loading', summaryData: null };
    },
    getSummarySuccess: (state, action) => {
      state.summary = { status: 'success', summaryData: action?.payload };
    },
    getSummaryError: (state, action) => {
      state.summary = { status: 'error', summaryData: null };
      toast(<AlertComponenet hideHeading msg={action?.payload} toastType="error" />, {
        toastId: 'jobs-summary-error'
      });
    },

    resetJobList: (state) => {
      state.jobsList.scheduledInProgress = { status: 'ready', scheduledInProgressData: null };
      state.jobsList.completed = { status: 'ready', completedData: null };
    },
    getJLScheduleInprogressInit: (state, action: PayloadAction<JobsListyReq>) => {
      state.jobsList.scheduledInProgress = {
        status: 'loading',
        scheduledInProgressData: state.jobsList.scheduledInProgress.scheduledInProgressData
      };
    },
    getJLScheduleInprogressSuccess: (state, action) => {
      let mergedData: any = [];
      if (state.jobsList.scheduledInProgress.scheduledInProgressData?.data) {
        mergedData = [
          ...state.jobsList.scheduledInProgress.scheduledInProgressData.data,
          ...action.payload.data
        ];
      } else {
        mergedData = action.payload.data;
      }

      state.jobsList.scheduledInProgress = {
        status: 'success',
        scheduledInProgressData: {
          ...state.jobsList.scheduledInProgress.scheduledInProgressData,
          data: mergedData,
          meta: action.payload.meta
        } as ApiResponse<JobsListyRes[] | null>
      };
    },
    getJLScheduleInprogressError: (state, action) => {
      state.jobsList.scheduledInProgress = { status: 'error', scheduledInProgressData: null };
      toast(<AlertComponenet hideHeading msg={action?.payload} toastType="error" />, {
        toastId: 'jobs-inp-list-error'
      });
    },
    getJLCompletedInit: (state, action: PayloadAction<JobsListyReq>) => {
      state.jobsList.completed = {
        status: 'loading',
        completedData: state.jobsList.completed.completedData
      };
    },
    getJLCompletedSuccess: (state, action) => {
      let mergedData: any = [];
      if (state.jobsList.completed.completedData?.data) {
        mergedData = [...state.jobsList.completed.completedData.data, ...action.payload.data];
      } else {
        mergedData = action.payload.data;
      }
      state.jobsList.completed = {
        status: 'success',
        completedData: {
          ...state.jobsList.completed.completedData,
          data: mergedData,
          meta: action.payload.meta
        } as ApiResponse<JobsListyRes[] | null>
      };
    },
    getJLCompletedError: (state, action) => {
      state.jobsList.completed = { status: 'error', completedData: null };
      toast(<AlertComponenet hideHeading msg={action?.payload} toastType="error" />, {
        toastId: 'jobs--complet-list-error'
      });
    }
  }
});

export const {
  resetJobList,
  resetAllSchedule,
  setJobsDetail,
  setJobTabValue,
  setNotificationIndicator,
  getJLScheduleInprogressInit,
  getJLScheduleInprogressSuccess,
  getJLScheduleInprogressError,
  getJLCompletedInit,
  getJLCompletedSuccess,
  getJLCompletedError,
  getSummaryInit,
  getSummarySuccess,
  getSummaryError
} = jobsSharedSlice.actions;

export default jobsSharedSlice.reducer;
