import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import Info from '../../../../assets/images/Info.svg';

interface InfoBoxProps {
  content?: string;
  showIcon?: boolean;
  contentList?: string[];
}

const InfoBox: FC<InfoBoxProps> = ({ content, contentList, showIcon = true }) => {
  return (
    <Box
      sx={{
        ...InfoBoxStyle
      }}>
      {showIcon && <img src={Info} alt="Info" />}
      <Typography
        variant="body2"
        gutterBottom
        fontSize={16}
        lineHeight="24px"
        color="primary"
        mb={0}>
        {content}
      </Typography>
      {contentList && (
        <Box
          sx={{
            ul: {
              pl: 1
            },
            li: {
              color: '#ddff55',
              mb: 1
            }
          }}>
          <ul>
            {contentList?.map((text) => (
              <li key={text}>{text}</li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default InfoBox;

export const InfoBoxStyle = {
  display: 'flex',
  padding: '8px 16px',
  borderRadius: '8px',
  border: '2px solid rgba(221, 255, 85, 0.08)',
  background: 'rgba(181, 209, 70, 0.08)',
  gap: 1.5,
  alignItems: 'center',
  mb: 2.6,
  mt: 2,
  mr: 0.5
};
