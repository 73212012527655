import { SagaIterator } from '@redux-saga/core';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  cancelBulkScheduleApi,
  createBulkScheduleApi,
  getMirrorInProgressMetricsApis,
  getPlannedOnboardingApis,
  getUnManagedVmApi,
  updateBulkScheduleApi
} from '../api';
// local import
import { getCurrentLocalTimestamp,getNumberOfInstanceFromPyaload } from '../utils/helper';
import UnmanagedVmInstrumentation from '../utils/instrumentation';
import * as slice from './unmanagedVm.slice';
import * as jobsSlice from 'features/jobs/component/shared/store/jobsShared.slice';
import { jobsAnalytics } from 'features/jobs/utils/analytics';

const unmanagedVmInstrumentation = new UnmanagedVmInstrumentation();

export function* onGetUnManagedVm({ payload }: any): SagaIterator {
  try {
    const data = yield call(() => getUnManagedVmApi(payload));
    yield put(slice.getUnManagedVmSuccess(data));
  } catch (e: any) {
    yield put(slice.getUnManagedVmError(e));
  }
}

export function* onGetMirrorMetrics({ payload }: any): SagaIterator {
  try {
    const { data } = yield call(() => getMirrorInProgressMetricsApis(payload));
    yield put(slice.geMirrorInprogressMetricsFulfilled(data));
  } catch (e: any) {
    yield put(slice.getMirrorInprogressMetricsRejected(e));
  }
}

export function* onGetPlannedOnboaring(): SagaIterator {
  try {
    const data = yield call(() => getPlannedOnboardingApis());
    yield put(slice.getPlannedOnboardingSuccess(data));
  } catch (e: any) {
    yield put(slice.getPlannedOnboardingError(e));
  }
}

export function* onCreateBulkSchedule({ payload }: any): SagaIterator {
  unmanagedVmInstrumentation.onboardingInitiated();
  const args = {
    noOfInstance: getNumberOfInstanceFromPyaload(payload),
    noOfPartitions: payload?.mountPointInfo?.length,
    onboardingStage: 'bulk_onboard_create',
    timeStamp: getCurrentLocalTimestamp()
  };

  try {
    const data = yield call(() => createBulkScheduleApi(payload));

    yield put(jobsSlice.setJobsDetail({ jobsId: parseInt(data.data.jobId) }));
    yield put(jobsSlice.setNotificationIndicator(true));

    yield put(slice.createBulkScheduleSuccess(data));
    unmanagedVmInstrumentation?.onboardingScheduled(args);
  } catch (e: any) {
    yield put(slice.createBulkScheduleError(e));
    unmanagedVmInstrumentation?.onboardingFailure({ ...args, error: e });
  }
}

export function* onUpdateBulkSchedule({ payload }: any): SagaIterator {
  const pathName = window.location.pathname;
  const args = {
    noOfInstance: getNumberOfInstanceFromPyaload(payload),
    noOfPartitions: payload?.mountPointInfo?.length,
    onboardingStage: 'bulk_onboard_update',
    timeStamp: getCurrentLocalTimestamp()
  };

  const jobsArgs = {
    action_type: 'update',
    number_of_instance: getNumberOfInstanceFromPyaload(payload),
    no_of_partitions: payload?.mountPointInfo?.length,
    job_type: 'Onboarding'
  } as any;

  try {
    const data = yield call(() => updateBulkScheduleApi(payload));
    yield put(
      jobsSlice.setJobsDetail({ jobsId: parseInt(data.data.jobId), onboardJobUpdateTrigger: true })
    );
    yield put(jobsSlice.setNotificationIndicator(true));
    yield put(slice.updateBulkScheduleSuccess(data));
    unmanagedVmInstrumentation?.onboardingScheduled(args);

    // jobs analytics
    if (pathName === '/jobs') {
      jobsAnalytics.jobsScheduleds({
        ...jobsArgs,
        job_id: data.data.jobId,
        status: 'success'
      });
    }
  } catch (e: any) {
    yield put(slice.updateBulkScheduleError(e));
    unmanagedVmInstrumentation?.onboardingFailure({ ...args, error: e });

    // jobs analytics
    if (pathName === '/jobs') {
      jobsAnalytics.jobsScheduleds({
        ...jobsArgs,
        status: 'failure',
        status_detail: e
      });
    }
  }
}

export function* onCancelBulkSchedule({ payload }: any): SagaIterator {
  const pathName = window.location.pathname;
  const args = {
    noOfInstance: getNumberOfInstanceFromPyaload(payload),
    noOfPartitions: payload?.mountPointInfo?.length,
    onboardingStage: 'bulk_onboard_cancel',
    timeStamp: getCurrentLocalTimestamp()
  };

  const jobsArgs = {
    number_of_instance: getNumberOfInstanceFromPyaload(payload),
    no_of_partitions: payload?.mountPointInfo?.length,
    job_type: 'Onboarding',
    action_type: 'cancel'
  } as any;

  try {
    const data = yield call(() => cancelBulkScheduleApi(payload));
    yield put(slice.cancelBulkScheduleSuccess(data));
    unmanagedVmInstrumentation?.onboardingScheduled(args);

    yield put(jobsSlice.setJobsDetail({ jobsId: -1, onboardJobUpdateTrigger: true }));
    yield put(jobsSlice.setNotificationIndicator(true));

    // jobs analytics
    if (pathName === '/jobs') {
      jobsAnalytics.jobsScheduleds({
        ...jobsArgs,
        job_id: data.data.jobId,
        status: 'success'
      });
    }
  } catch (e: any) {
    yield put(slice.cancelBulkScheduleError(e));
    unmanagedVmInstrumentation?.onboardingFailure({ ...args, error: e });
    // jobs analytics
    if (pathName === '/jobs') {
      jobsAnalytics.jobsScheduleds({
        ...jobsArgs,
        status: 'failure',
        status_detail: e
      });
    }
  }
}

// Watcher Saga
export function* unManagedVmWatcherSagas(): SagaIterator {
  yield takeLatest('unmanagedvm/getUnManagedVm', onGetUnManagedVm);
  yield takeLatest('unmanagedvm/getMirrorInprogressMetrics', onGetMirrorMetrics);
  yield takeLatest('unmanagedvm/bulkScheduleInit', onCreateBulkSchedule);
  yield takeLatest('unmanagedvm/getPlannedOnboarding', onGetPlannedOnboaring);
  yield takeLatest('unmanagedvm/updatebulkScheduleInit', onUpdateBulkSchedule);
  yield takeLatest('unmanagedvm/cancelbulkScheduleInit', onCancelBulkSchedule);
}

export default unManagedVmWatcherSagas;
