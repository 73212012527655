import * as amplitude from '@amplitude/analytics-browser';
import {
  FeaturedAccessedArgs,
  JobLists,
  JobsEngagements,
  JobsScheduleds,
  ProgressExportedArgs
} from '../types';

class JobsAnalytics {
  private exportProgress = 'jobs_export_progress';
  private jobsFeatureAccessed = 'jobs_feature_accessed';
  private jobsLists = 'jobs_lists';
  private jobsScheduled = 'jobs_scheduled';
  private jobsEngagement = 'jobs_engagement';
  private featureName = 'jobs';

  getFeatureName() {
    return { feature_name: this.featureName, timestamp: new Date().toLocaleString() };
  }

  progressExported(args: ProgressExportedArgs) {
    const eventProps = {
      ...this.getFeatureName(),
      ...args
    };
    this.pushEvent(this.exportProgress, eventProps);
  }

  featuredAccessed(args: FeaturedAccessedArgs) {
    const eventProps = {
      ...this.getFeatureName(),
      ...args
    };
    this.pushEvent(this.jobsFeatureAccessed, eventProps);
  }

  jobLists(args: JobLists) {
    const eventProps = {
      ...this.getFeatureName(),
      ...args
    };
    this.pushEvent(this.jobsLists, eventProps);
  }

  jobsScheduleds(args: JobsScheduleds) {
    const eventProps = {
      ...this.getFeatureName(),
      ...args
    };
    this.pushEvent(this.jobsScheduled, eventProps);
  }

  jobsEngagements(args: JobsEngagements) {
    const eventProps = {
      ...this.getFeatureName(),
      ...args
    };
    this.pushEvent(this.jobsEngagement, eventProps);
  }

  private pushEvent(eventName: string, eventProps: any) {
    amplitude.track(eventName, eventProps);
  }
}

export const jobsAnalytics = new JobsAnalytics();
