import { combineReducers } from '@reduxjs/toolkit';
import jobsSharedSlice from '../component/shared/store/jobsShared.slice';
import onboardingsJobsSlice from '../component/onboardings/store/onboardings.slice';
import agentInstallJobsSlice from '../component/agentInstall/store/agentInstall.slice'

export const jobsRootSlice = combineReducers({
  jobsShared: jobsSharedSlice,
  onboardings: onboardingsJobsSlice,
  agentInstall: agentInstallJobsSlice
});
