import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import GlobalSpinner from 'components/GlobalSpinner';
import { accountsGrpahScope, accountsManagementScope } from 'features/auth/config/azureConfig';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUndefineNull } from 'utils/common';
import storage from 'utils/storage';
import { CloudEntitySetupRequest } from '../../shared/types';
import { cloudEntityOnboardApiV1 } from '../../shared/api';
import toast from 'react-hot-toast';
import config from 'config';
import { removeGraphToken } from '../utils/helper';

const AzureCallback = () => {
  const navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();
  const isAuth = useIsAuthenticated();
  const [showSubscription, setShowSubscription] = useState<boolean | undefined>(undefined);
  const [inprogress, setInprogress] = useState<boolean>(false);

  const callCloudEntityApi = (
    azureGraphToken: string | undefined,
    azureManagementToken: string
  ) => {
    setInprogress(true);
    const payload: CloudEntitySetupRequest = {
      cloudEntityIds: storage.gettUpdatePermission()?.cloudEntityId,
      azureGraphToken: azureGraphToken,
      azureManagementToken: azureManagementToken,
      productList: ['AUTO_SCALER'],
      cloudProvider: 'AZURE'
    };
    cloudEntityOnboardApiV1(payload)
      .then(() => {
        toast.success('Permission update is in progress. Please wait a few minutes');
        setTimeout(() => {
          setInprogress(false);
          navigate('/accounts');
        }, 600);
      })
      .catch(() => {
        toast.error('Fail updating permission', { id: 'update-permission-fail' });
        setTimeout(() => {
          setInprogress(false);
          navigate('/accounts');
        }, 600);
      });
  };

  if (!isAuth && inProgress !== InteractionStatus.None) instance.loginRedirect(accountsManagementScope);

  if (!storage.getGraphToken()) {
    const diableAccessToGraphToken = config.disableFeature;
    if (diableAccessToGraphToken?.split(',')?.includes(storage.getXAccount())) {
      storage.setGraphToken('skip'); // skip the graph token for quest, TODO: remove this
    } else {
      if (isAuth) {
        instance
          .acquireTokenSilent({
            scopes: accountsGrpahScope.scopes,
            account: accounts[0]
          })
          .then((res) => {
            storage.setGraphToken(res?.accessToken);
          })
          .catch(async (e) => {
            if (e instanceof InteractionRequiredAuthError) {
              await instance.acquireTokenRedirect({
                scopes: accountsGrpahScope.scopes,
                account: accounts[0]
              });
            } else {
              setShowSubscription(false);
            }
          });
      }
    }
  }
  if (!storage.getManagentToken()) {
    if (isAuth) {
      instance
        .acquireTokenSilent({
          scopes: accountsManagementScope.scopes,
          account: accounts[0]
        })
        .then((res) => {
          storage.setManagentToken(res?.accessToken);
          setShowSubscription(true);
          if (!isUndefineNull(storage.getManagentToken())) {
            if (!storage.gettUpdatePermission()?.isUpdate) {
              navigate('/accounts/azure');
            }
          }
        })
        .catch(async (e) => {
          if (e instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              scopes: accountsManagementScope.scopes,
              account: accounts[0]
            });
          } else {
            setShowSubscription(false);
          }
        });
    }
  }

  useEffect(() => {
    if (!isUndefineNull(storage.getGraphToken()) && !isUndefineNull(storage.getManagentToken())) {
      if (storage.gettUpdatePermission()?.isUpdate) {
        if (!inprogress) {
          callCloudEntityApi(removeGraphToken(storage.getGraphToken()), storage.getManagentToken());
        }
      } else {
        navigate('/accounts/azure');
      }
    }
  }, [showSubscription]);

  if (
    inProgress === InteractionStatus.None &&
    isUndefineNull(storage.getGraphToken()) &&
    isUndefineNull(storage.getManagentToken())
  ) {
    setTimeout(() => {
      if (!storage.gettUpdatePermission()?.isUpdate) {
        navigate('/accounts/azure');
      }
    }, 2000);
    return <GlobalSpinner height="80vh" />;
  }

  if (inProgress !== InteractionStatus.None || inprogress) return <GlobalSpinner height="80vh" />;

  return <></>;
};

export default AzureCallback;
