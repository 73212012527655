import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  allInstanceConversionSupportMsg,
  anyPartitonAlreadyOnboarded,
  partialInstancceConversionSupportMsg
} from '../constant';
import { setBulkOnboardSchedulePayload, setScheduleModalProps } from '../store';
import {
  BulkOnboardInstancesInfo,
  InstanceCompatibleInfoType,
  ScheduleInstanceInfo
} from '../types';
import UnmanagedVmInstrumentation from '../utils/instrumentation';
import { getNumberOfInstanceFromPyaload } from '../utils/helper';

const unmanagedVmInstrumentation = new UnmanagedVmInstrumentation();

export const useDiskTypeConversion = () => {
  const dispatch = useAppDispatch();
  const { bulkOnboardSchedulePayload, scheduleModalPropsList } = useAppSelector(
    (state) => state.unmanagedVm
  );
  const [checked, setChecked] = useState<boolean>(false);
  const [hoverActive, setHoverActive] = useState<boolean>(false);
  const [instanceCompatibleInfo, setInstanceCompatibleInfo] = useState<InstanceCompatibleInfoType>({
    compatible: 0,
    nonCompatible: 0,
    total: 0
  });
  const [anyPartitionOnboarded, setAnyPartitionOnboarded] = useState<boolean | undefined>(false);
  const diskTypeCoversionFeatureEnable = scheduleModalPropsList?.diskCoversionFeatureEnable;

  const getInstanceCompatibleForConversion = () => {
    const instancesCompatibleCheck = { compatible: 0, nonCompatible: 0, total: 0 };
    scheduleModalPropsList?.scheduleInstanceInfo?.map((itm: ScheduleInstanceInfo) => {
      if (itm?.instanceCompatibleForV2Onboard) {
        instancesCompatibleCheck.compatible += 1;
      } else {
        instancesCompatibleCheck.nonCompatible += 1;
      }
    });
    instancesCompatibleCheck.total =
      instancesCompatibleCheck?.compatible + instancesCompatibleCheck?.nonCompatible;
    setInstanceCompatibleInfo(instancesCompatibleCheck);
  };

  const shoudlChekBoxBeChecked = () => {
    if (
      (diskTypeCoversionFeatureEnable || anyPartitionOnboarded) &&
      isInstanceCompatibleForV2Onboard()
    ) {
      setChecked(true);
      dispatch(
        setScheduleModalProps({
          isConversionCheckBoxChecked: true
        })
      );
    }
  };

  const checkInstanceForAnyPartitionOnboarded = () => {
    return scheduleModalPropsList?.scheduleInstanceInfo?.some((itm: ScheduleInstanceInfo) => {
      return itm?.anyPartitionOnboardedToV2 === true;
    });
  };

  useEffect(() => {
    getInstanceCompatibleForConversion();
    shoudlChekBoxBeChecked();
    setAnyPartitionOnboarded(checkInstanceForAnyPartitionOnboarded());
  }, []);

  const addOnboardingDiskTypeInPayload = (diskType: string | undefined) => {
    return bulkOnboardSchedulePayload?.mountPointInfo?.map((mp: BulkOnboardInstancesInfo) => {
      return { ...mp, onboardingDiskType: diskType };
    });
  };

  const handleCheckBoxForDiskTypeConversion = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checkStatus = event.target.checked;
    dispatch(
      setScheduleModalProps({
        isConversionCheckBoxChecked: checkStatus
      })
    );
    setChecked(checkStatus);
    let mountPointInfo = null;
    if (!checkStatus) {
      mountPointInfo = addOnboardingDiskTypeInPayload(undefined);
    } else {
      if (diskTypeCoversionFeatureEnable)
        mountPointInfo = addOnboardingDiskTypeInPayload('PremiumV2_LRS');
    }
    dispatch(
      setBulkOnboardSchedulePayload({
        mountPointInfo: mountPointInfo
      })
    );

    // instrumentation
    unmanagedVmInstrumentation.diskConversion({
      conversionCheckBoxChecked: checkStatus,
      noOfInstance:
        bulkOnboardSchedulePayload !== null
          ? getNumberOfInstanceFromPyaload(bulkOnboardSchedulePayload)
          : 0,
      noOfPartitions: bulkOnboardSchedulePayload?.mountPointInfo?.length ?? 0,
      compatibilityStatus: anyPartitonOnboardedWithAdditionalInstanceAndCoversionCompatible()
        ? 'partial'
        : 'all'
    });
  };

  const anyPartitonOnboardedWithAdditionalInstanceAndCoversionCompatible = () => {
    // two instance selected, one instance anypartition onboarded and another instance partition is not compatible
    if (diskTypeCoversionFeatureEnable && scheduleModalPropsList?.scheduleInstanceInfo) {
      if (scheduleModalPropsList?.scheduleInstanceInfo?.length === 2) {
        const anyOnboardedAndNotSupported = [
          scheduleModalPropsList?.scheduleInstanceInfo?.[0]?.instanceCompatibleForV2Onboard,
          scheduleModalPropsList?.scheduleInstanceInfo?.[1]?.instanceCompatibleForV2Onboard
        ];
        if (
          anyOnboardedAndNotSupported.includes(true) &&
          anyOnboardedAndNotSupported.includes(false)
        ) {
          return false;
        }
      } else if (
        scheduleModalPropsList?.scheduleInstanceInfo?.length > 1 &&
        anyPartitionOnboarded
      ) {
        return true;
      }
    }
  };

  const isCurrentDiskRequirementsForV2IconDisplay = () => {
    return scheduleModalPropsList?.scheduleInstanceInfo?.some((itm: ScheduleInstanceInfo) => {
      return itm.currentDiskRequirementsForV2IconDisplay;
    });
  };

  const isInstanceCompatibleForV2Onboard = () => {
    return scheduleModalPropsList?.scheduleInstanceInfo?.some((itm: ScheduleInstanceInfo) => {
      return itm.instanceCompatibleForV2Onboard;
    });
  };

  const disableCheckBox = () => {
    if (anyPartitonOnboardedWithAdditionalInstanceAndCoversionCompatible()) {
      return false;
    }
    return (
      !diskTypeCoversionFeatureEnable ||
      anyPartitionOnboarded ||
      !isInstanceCompatibleForV2Onboard()
    );
  };

  const toolTipTextOnCheckBox = () => {
    if (!anyPartitonOnboardedWithAdditionalInstanceAndCoversionCompatible()) {
      return anyPartitonAlreadyOnboarded;
    }
  };

  const disableShowToolTipOnCheckBox = () => {
    return !anyPartitionOnboarded;
  };

  const handleHoverStateIn = () => {
    if (!diskTypeCoversionFeatureEnable) setHoverActive(true);
  };

  const handleHoverStateOut = () => {
    setHoverActive(false);
  };

  const getCheckBoxLabel = () => {
    if (!diskTypeCoversionFeatureEnable) {
      return allInstanceConversionSupportMsg;
    }
    if (instanceCompatibleInfo?.nonCompatible > 0 && instanceCompatibleInfo?.total > 1) {
      return partialInstancceConversionSupportMsg.replace(
        '{}',
        `${instanceCompatibleInfo?.compatible}/${instanceCompatibleInfo?.total}`
      );
    } else {
      return allInstanceConversionSupportMsg;
    }
  };

  return {
    checked,
    hoverActive,
    instanceCompatibleInfo,
    diskTypeCoversionFeatureEnable,
    getCheckBoxLabel,
    handleHoverStateOut,
    handleHoverStateIn,
    disableShowToolTipOnCheckBox,
    toolTipTextOnCheckBox,
    disableCheckBox,
    handleCheckBoxForDiskTypeConversion,
    isCurrentDiskRequirementsForV2IconDisplay,
    isInstanceCompatibleForV2Onboard,
    anyPartitonOnboardedWithAdditionalInstanceAndCoversionCompatible
  };
};
