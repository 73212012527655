import { Box, Button, IconButton, TableCell, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const tableHeader = ['VM details', 'Utilization', 'Total Unrealized Savings', 'Status', ' '];

interface TableHeaderProps {
  toggleAllRows: () => void;
  areAllExpanded: boolean;
}

const TableHeader: FC<TableHeaderProps> = ({ toggleAllRows, areAllExpanded }) => {
  return (
    <TableHead>
      <TableRow
        sx={{
          '.MuiTableRow-root': {
            backgroundColor: 'rgba(31, 32, 35, 0.28)'
          }
        }}>
        {tableHeader.map((header: string, index: number) => {
          return (
            <TableCell
              align="left"
              key={`${header + index}`}
              sx={{
                pl: `${index === 0 ? '44px' : '18px'}`,
                width: `${index === tableHeader.length - 1 ? '80px' : '280px'}`,
                py: '24px',
                borderLeft: `${index === 4 ? '1px solid #454546' : 'none'}`
              }}>
              {index !== 4 ? (
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: '#161718',
                    border: 'none',
                    fontSize: '14px',
                    px: `10px`,
                    '&:hover': {
                      backgroundColor: '#161718',
                      border: 'none'
                    }
                  }}>
                  {header}
                </Button>
              ) : (
                <Box>
                  <Box
                    onClick={toggleAllRows}
                    sx={{
                      cursor: 'pointer',
                      textAlign: 'center'
                    }}>
                    <IconButton
                      aria-label="expand row"
                      size="large"
                      sx={{
                        height: 28,
                        width: 28,
                        borderRadius: '50%',
                        backgroundColor: 'rgba(221, 255, 85, 0.08)',
                        color: '#DDFF55',
                        transform: `${areAllExpanded ? 'rotate(0deg)' : 'rotate(180deg)'}`,
                        transition: 'all 0.2s ease-in-out'
                      }}>
                      <KeyboardArrowUpIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
