import { useState } from 'react';
import { useAppSelector } from 'store/hooks';

const useToggleAllRows = () => {
  const { unmanagedVmsData } = useAppSelector((state) => state.unmanagedVm);
  const rowCount = unmanagedVmsData?.data?.length;
  const [expandedRows, setExpandedRows] = useState<boolean[]>(Array(rowCount).fill(false));
  const toggleAllRows = () => {
    const checkAreAllExpanded = expandedRows.every((state) => state);
    setExpandedRows(Array(rowCount).fill(!checkAreAllExpanded));
  };
  const toggleRow = (index: number) => {
    setExpandedRows((prev) => prev.map((state, i) => (i === index ? !state : state)));
  };
  const areAllExpanded = expandedRows.every((state) => state);
  return { expandedRows, toggleRow, toggleAllRows, areAllExpanded };
};

export default useToggleAllRows;
